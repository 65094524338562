var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"582S4Ums8jD5bvhHd8dR9"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = 'https://af840ab64d820f4a71a439eea471f6fb@sentry.apps.k8s.prod.domoy.ru/258';
const SENTRY_TUNNEL = '/kx6eqv2b';

Sentry.init({
  dsn: SENTRY_DSN,
  tunnel: SENTRY_TUNNEL ? SENTRY_TUNNEL : undefined,
  integrations: [
    Sentry.replayIntegration(),
    Sentry.browserProfilingIntegration(),
  ],
  // Игнорируем ошибку, так как это внутренняя ошибка Sentry - [https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062]
  ignoreErrors:[
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
   ],
  maxValueLength: 10_000,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  beforeSend: (event) => {
    // Игнорируем ошибку, так как это внутренняя ошибка конкретной версии Next.js - Error: Invariant: attempted to hard navigate to the same URL [https://github.com/vercel/next.js/issues/43088]
    if (event.exception?.values?.[0].value.includes('Invariant: attempted to hard navigate to the same URL')) {
      return null;
    }
    return event;
  },
});
